import axios from 'axios'
// import store from './store.js'
import sitePackage from '../package.json'
const instance = axios.create()
instance.interceptors.request.use(function (config) {
  config.headers.common['Grace-Systems'] = `grace-website-${process.env.VUE_APP_BUILDSET === 'ProDev' ? 'development' : process.env.NODE_ENV}-${sitePackage.version}` // eslint-disable-line dot-notation
  return config
}, function (error) {
  return Promise.reject(error)
})
export default instance
